* {
  box-sizing: border-box;
}
html {
  font-family: "DM Sans", sans-serif;
  color: white;
}
@font-face {
  font-family: "DM Sans";
  src: url("./resources/font/DMSans-Regular.ttf");
}
.main {
  padding: 32px 20px 24px 20px;
  width: 100%;
  min-height: 100vh;
  background: url("/public/images/background.jpg");
  background-size: cover;
  background-position: center;
}
.container {
  margin: auto;
  width: 100%;
  max-width: 1024px;
}
.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-header .left-header {
  display: flex;
  align-items: center;
}
.left-header .logo {
  height: 50px;
  margin-right: 8px;
}
.left-header .brand {
  font-size: 20px;
}
.container .content {
  margin: auto;
  max-width: 42rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 20px;
}
.content .brand-title {
  color: #e100a2;
  font-size: 44px;
  font-weight: 700;
  text-align: center;
  margin: 0;
}
.content .title {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 0;
  text-align: center;
  margin-top: 12px;
}
.content .intro {
  color: rgb(227, 227, 227);
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  line-height: 32px;
}
.content > div {
  margin-top: 20px;
  display: flex;
  align-items: center;
  text-align: center;
}
.content .btn-left img {
  height: 60px;
}
@media screen and (max-width: 768px) {
  .container .content {
    padding: 50px 18px 20px 18px;
  }
  .content > div {
    flex-direction: column;
    width: 100%;
    padding: 0 12px;
  }
  .content .btn-left {
    margin-bottom: 12px;
  }
  .content .brand-title {
    font-size: 32px;
  }
  .content .title {
    font-size: 24px;
  }
  .content .intro {
    font-size: 14px;
    line-height: 21px;
  }
  .content > div {
    margin-top: 10px;
  }
  .content .content-logo {
    width: 220px;
  }
  .main-header .btn {
    display: none;
  }
  .content .btn-left img {
    height: 44px;
  }
}
